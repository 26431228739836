import { graphql } from "gatsby"
import PostsPage from "../components/posts"

export default PostsPage

export const query = graphql`
  query PostsPageQuery(
    $limit: Int!,
    $filter: BlogPostFilterInput,
    $maxWidth: Int
  ) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    allBlogPost(
      sort: { fields: [date, title], order: DESC }
      filter: $filter
      limit: $limit
    ) {
      nodes {
        id
        excerpt
        slug
        title
        date(formatString: "DD [de]  MMMM, YYYY", locale: "es")
        createdAt: date
        image {
          childImageSharp {
            gatsbyImageData(width: $maxWidth)
          }
        }
        imageAlt
        imageCaptionText
        imageCaptionLink
        socialImage {
          childImageSharp {
            gatsbyImageData(width: 1600)
          }
        }
        tags
      }
    }
  }
`
