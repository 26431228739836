import * as React from "react"
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from 'gatsby-theme-mate/src/theme';
import { Helmet } from 'react-helmet';
import Footer from '../gatsby-theme-mate/components/Footer';
import Header from '../gatsby-theme-mate/components/Header';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before { 
    box-sizing: inherit;
  }
  html { 
    scroll-behavior: smooth;
  }
  body {
    box-sizing: border-box; 
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    font-family: ${(props) => props.theme.fonts.body};
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }
  main {
    min-height: calc(100vh - 71px);
  }
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet" />
    </Helmet>
    <GlobalStyle />
    <Header initialColor />
    {children}
    <Footer />
  </ThemeProvider>
);

export default Layout;
