import * as React from "react"
import Layout from "./layout"
import SEO from "./seo"
import { Box } from 'rebass/styled-components';
import { CardContainer } from 'gatsby-theme-mate/src/components/Card';
import PostLink from './post-link';
import Link from 'gatsby-theme-mate/src/components/Link';
import { Heading } from 'rebass/styled-components';

const Posts = ({ location, posts, siteTitle }) => (
  <Layout location={location} title={siteTitle}>
    <SEO title="Blog | EnfermeraProgramadora.cl" />
    <main>
      <Box maxWidth="1200px" mx="auto" as="article" px={["16px"]} pt={["80px", "100px"]} pb={["20px", "40px"]}>
        <Heading as="h1" color="text" mb={4}>
          <Link selected>
            Blog
            <span role="img" aria-label="" style={{ marginLeft: '10px' }}>✍️</span>
          </Link>
        </Heading>
        <CardContainer minWidth="300px">
          {posts.map(node => (
            <PostLink key={node.url || node.slug} {...node}/>
          ))}
        </CardContainer>
      </Box>
    </main>
  </Layout>
)

export default Posts
