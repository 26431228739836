import * as React from "react"
import Posts from "../../components/posts"
import { useNessyQuery } from '../../utils/use-nessy-query';
import { useMediumQuery } from '../../utils/use-medium-query';
import { combinePosts } from '../../utils/combine-posts';

const PostsWrapper = ({ location, data }) => {
  const { site, allBlogPost } = data
  const nessyPosts = useNessyQuery();
  const { posts: mediumPosts } = useMediumQuery();
  const allPosts = combinePosts(nessyPosts, mediumPosts, allBlogPost.nodes);

  return (
    <Posts
      location={location}
      posts={allPosts}
      siteTitle={site.siteMetadata.title}
      socialLinks={site.siteMetadata.social}
    />
  )
}

export default PostsWrapper
